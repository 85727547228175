body {
  margin: 0;
}
@font-face {
  font-family: "Nasalization";
  src: local("Nasalization"),
   url("./fonts/nasalization/nasalization-rg.ttf") format("truetype");
  font-weight: 400;
 }
 @font-face {
  font-family: "Greycliff CF";
  src: local("Greycliff CF"),
   url("./fonts/greycliff/GreycliffCF-Regular.ttf") format("truetype");
   font-style: normal;
   font-weight: 400;
 }

 @font-face {
  font-family: "Greycliff CF";
  src: local("Greycliff CF"),
   url("./fonts/greycliff/GreycliffCF-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700 !important;
 }
 @font-face {
  font-family: "Greycliff CF";
  src: local("Greycliff CF"),
   url("./fonts/greycliff/GreycliffCF-DemiBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600 !important;
 }
 @font-face {
  font-family: "Greycliff CF";
  src: local("Greycliff CF"),
   url("./fonts/greycliff/GreycliffCF-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500 !important;
 }


.app-sidebar {
  flex-grow: 0;
  flex-shrink: 0;
  /* min-width: 150px;
  max-width: 300px; */
  display: flex;
  border-right: #e9e9e9 1px solid;
  flex-direction: row;
  background: #ffffff;
  box-shadow: -8px 2px 22px -7px rgba(0, 0, 0, 0.25);
  border-radius: 10px 0px 0px 10px;
  z-index: 2;
}

.app-frame {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: white;
  height: 100vh;
  max-height: 100%;
  background: #ffffff;
  box-shadow: 8px 2px 32px -2px rgba(0, 0, 0, 0.25);
  border-radius: 0px 10px 10px 0px;
  z-index: 1;
}

.app-sidebar .app-sidebar-content {
  flex: 1;
}

.app-sidebar .app-sidebar-resizer {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 6px;
  justify-self: flex-end;
  cursor: col-resize;
  resize: horizontal;
}

.app-sidebar .app-sidebar-resizer:hover {
  width: 3px;
  background: green;
}